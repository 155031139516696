<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="724px"
  >
    <v-card class="px-4 py-0 overflow-hidden">
      <v-card-subtitle class="ma-0 py-4 px-0 d-flex justify-space-between align-center">
        <h2 class="font-weight-regular" style="color: #212B36;">{{ title }}</h2>

        <v-tooltip top content-class="v-tooltip--top">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="close"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="20px">close</v-icon>
            </v-btn>
          </template>

          <span>Close</span>
        </v-tooltip>
      </v-card-subtitle>

      <v-card-actions class="py-0 px-3">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-divider
              class="hl-724"
            ></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0"
          >
            <v-text-field
              solo
              outlined
              placeholder="Search callflow"
              type="search"
              v-model="search"
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <v-icon color="#919EAB" size="20px" class="mt-0">search</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-divider
              class="hl-724"
            ></v-divider>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-list :loading="loading">
        <template v-for="(callflow, callflowIndex) in callflows">
          <v-divider
            v-if="callflowIndex"
            :key="callflowIndex + 'divider'"
            class="hl-724"
          ></v-divider>

          <v-list-item :key="callflowIndex + 'callflow'" class="pa-0">
            <v-list-item-content class="py-2">
              <v-list-item-title class="fz-14 font-weight-semi-bold">
                {{ callflow.name }}
              </v-list-item-title>

              <!-- <v-list-item-subtitle
                class="py-1"
                style="color: #637381;"
              >
                {{ callflow.subtitle }}
              </v-list-item-subtitle> -->
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center ma-0">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="add(callflow.uuid)"
                  >
                    <v-icon color="#919EAB">{{ callflow.uuid === number.callFlowUuid ? 'remove' : 'add' }}</v-icon>
                  </v-btn>
                </template>

                <span>{{ callflow.uuid === number.callFlowUuid ? 'Unassign' : 'Assign' }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'AssignCallflow',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    number: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    title: 'Assign callflow',
    search: '',
    callflows: [],
    loading: false
  }),
  watch: {
    search (filter) {
      console.log(filter)
      this.callflowList({ filter })
    }
  },
  created () {
    console.log(this.number, 'this.number')
    this.callflowList()
  },
  methods: {
    ...mapActions([
      'getCallflows', 'addCallflowToDid', 'changeCallflowInDid', 'removeCallflowFromDid'
    ]),
    close: function () {
      this.$emit('close', {})
    },
    async callflowList (params = {}) {
      try {
        this.loading = true
        const { data } = await this.getCallflows(params)
        this.callflows = data.payload.items
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    add: async function (value) {
      try {
        if (Object.keys(this.number).length === 0) return
        this.$snotify.notifications = []
        let res = null
        let message = 'Callflow was assigned'
        if (!this.number.callFlowUuid) {
          res = await this.addCallflowToDid({
            didUuid: this.number.uuid,
            callFlowUuid: value
          })
        } else if (this.number.callFlowUuid === value) {
          res = await this.removeCallflowFromDid({ didUuid: this.number.uuid })
          message = 'Callflow was unassigned'
        } else {
          res = await this.changeCallflowInDid({
            didUuid: this.number.uuid,
            callFlowUuid: value
          })
          message = 'Assigned callflow was changed'
        }
        if (!res.data || !res.data.success) return
        this.$emit('close', { message })
      } catch (error) {
        this.$emit('close', { error })
      }
    }
  }
}
</script>
