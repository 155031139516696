<template>
  <div class="pt-4 pt-md-6 main" :class="{ isDesktop }">
    <div>
      <h1
        :class="isDesktop ? 'fz-28 line-h-32 font-weight-semi-bold' : 'fz-25 font-weight-semi-bold'"
        class="dark-text mb-1 px-5 px-md-0"
      >
        Phone Numbers
      </h1>
      <div class="d-flex mb-2 pr-3 pl-0 px-md-0 justify-md-start">
        <v-btn
          text
          class="text-none align-center px-2 ml-2 ml-md-0 d-flex"
          height="36px"
          min-width="auto"
          @click="buyNewNumber"
        >
          <v-icon
            size="22px"
            color="#A7A5B1"
            class="mr-1"
          >
            add
          </v-icon>
          <span class="velvet-grey">
            Buy new number
          </span>
        </v-btn>
        <v-btn
          v-if="!isFreeAccount || isFreeAccount && pageTotal"
          :to="{ name: 'Callflow'}"
          text
          class="text-none align-center px-2 d-flex ml-1"
          height="36px"
          min-width="auto"
        >
          <v-icon
            size="22px"
            color="#A7A5B1"
            class="mr-1"
          >
            call_split
          </v-icon>
          <span class="velvet-grey">
            Callflows
          </span>
        </v-btn>
        <!-- <div class="ml-1">
          <SelectComponent
            :value="['More Actions', 'Edit information', 'Remove']"
            :selectWidth="'max-content !important'"
            new-class="more-actions"
            select-icon="arrow_drop_down"
            @selectedValue="ChangeAction($event)"
          />
        </div> -->
      </div>
    </div>
    <v-card class="fz-14 mb-5" :loading="loading">
      <v-list class="py-0">
        <div v-if="!items.length && !loading" class="d-flex justify-center pa-4 font-weight-bold">
          <span>No phone numbers</span>
        </div>
        <div v-else>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            item-key="id"
            hide-default-footer
            disable-sort
          >
            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.autorenew`]="{ item }">
              <v-switch
                v-model="item.autorenew"
                :disabled="!item.expireOn"
                class="mt-0"
                inset
                hide-details
              />
            </template>
            <template v-slot:[`item.action`]="{ item, index }">
              <div class="d-flex align-center justify-center">
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-1 px-0"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click="assignUser(assignUserPopUp = !assignUserPopUp, item)"
                    >
                      <v-icon color="#919EAB">person_add</v-icon>
                    </v-btn>
                  </template>

                  <span>Assign To User</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-1 px-0"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      :to="{ name: 'CreateCallflow', params: { number: item.uuid } }"
                    >
                      <v-icon color="#919EAB">dialpad</v-icon>
                    </v-btn>
                  </template>

                  <span>Create Callflow</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-1 px-0"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="interact('assign', item)"
                    >
                      <!-- @click.stop.prevent="interact('callflow', item.number)" -->
                      <v-icon color="#919EAB">call_split</v-icon>
                    </v-btn>
                  </template>

                  <span>Assign Callflow</span>
                </v-tooltip>
                <v-tooltip v-if="item.extensions.length" top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click="openExtensionDialog(item, index)"
                    >
                      <v-icon color="#919EAB">extension</v-icon>
                    </v-btn>
                  </template>
                  <span>View Extensions</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-1"
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="interact(item.subscriptionId ? 'extend' : 'subscribe', item)"
                    >
                      <v-icon color="#919EAB">{{ item.subscriptionId ? 'expand' : 'trending_up' }}</v-icon>
                    </v-btn>
                  </template>

                  <span>{{ item.subscriptionId ? 'Extend' : 'Subscribe'}}</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      height="36px"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="interact('release', item.didNumber)"
                    >
                      <v-icon color="#919EAB">delete</v-icon>
                    </v-btn>
                  </template>

                  <span>Release</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </div>
        <v-divider />
        <div v-if="pageTotal > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider
                v-if="index"
                :key="index + 'v-divider--page'"
                vertical
                class="pa-0"
              />
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>

      <v-dialog persistent v-model="extensionDialog" max-width="660px">
        <v-card>
          <v-card-title class="font-weight-bold">
            Extensions
          </v-card-title>

          <v-card-text class="pa-0">
            <v-row v-if="isDesktop" align="center" class=" ma-0 pa-2">
              <!-- <v-col cols="1" class="pa-0">
                <v-checkbox
                  v-model="selectAllExtensions"
                  color="primary"
                  hide-details
                  class="mt-0 ml-2"
                  @change="selectAll($event, itemIndex)"
              ></v-checkbox>
              </v-col> -->
              <v-col cols="2" class="pa-0 ml-2">Extension</v-col>
              <v-col cols="4" class="pa-0">Assigned member</v-col>
              <v-col cols="2" class="pa-0">Status</v-col>
              <v-col cols="3" class="pa-0"></v-col>
            </v-row>
            <template v-for="(extension) in dialogExtensions">
              <v-divider :key="extension.id + 'ext'" />
              <v-row :key="extension.id + 'row'" align="center" class=" ma-0 pa-2">
                <!-- <v-col cols="1" class="pa-0">
                  <v-checkbox
                    v-model="extension.isSelected"
                    color="primary"
                    hide-details
                    class="ma-0 pa-0 ml-md-2"
                ></v-checkbox>
                </v-col> -->
                <v-col cols="2" class="pa-0 ml-2">
                  ×{{extension.extensionNumber}}
                </v-col>
                <v-col cols="4" class="pa-0">{{extension.assignedUserName}}</v-col>
                <v-col cols="3" md="2" class="pa-0">
                  <v-chip
                    class="ma-0"
                    :color="extension.enable ? '#BBE5B3' : '#FDAC9A'"
                    text-color="black"
                    small
                  >
                    {{extension.enable ? 'Active' : 'Disabled'}}
                  </v-chip>
                </v-col>
                <!-- <v-col
                  v-if="!extension.enable"
                  cols="2"
                  md="3"
                  class="d-flex align-center justify-end pa-0"
                >
                  <v-tooltip top content-class="v-tooltip--top">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="text-none align-center px-md-2 px-0 d-flex ml-md-1"
                        height="36px"
                        min-width="auto"
                        v-bind="attrs"
                        v-on="on"
                        @click="blockExtension(extension)"
                      >
                        <v-icon color="#919EAB">check</v-icon>
                      </v-btn>
                    </template>

                    <span>Unblock</span>
                  </v-tooltip>
                </v-col> -->
                <v-col
                  v-if="extension.enable"
                  cols="2"
                  md="3"
                  class="d-flex align-center justify-end pa-0"
                >
                  <!-- <v-tooltip top content-class="v-tooltip--top">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="text-none align-center px-md-2 px-0 d-flex ml-md-1"
                        height="36px"
                        min-width="auto"
                        v-bind="attrs"
                        v-on="on"
                        @click="blockExtension(extension)"
                      >
                        <v-icon color="#919EAB">block</v-icon>
                      </v-btn>
                    </template>

                    <span>Block</span>
                  </v-tooltip> -->
                  <v-tooltip top content-class="v-tooltip--top">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="text-none align-center px-md-2 px-0 d-flex ml-1"
                        height="36px"
                        min-width="auto"
                        v-bind="attrs"
                        v-on="on"
                        @click="removeExtension(extension.uuid)"
                      >
                        <v-icon color="#919EAB">delete</v-icon>
                      </v-btn>
                    </template>

                    <span>Delete</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-center">
            <v-btn depressed class="text-capitalize" @click="closeExtensionDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="releaseDialog" max-width="450px">
        <v-card>
          <v-card-title class="font-weight-bold">
            Release Number
          </v-card-title>
          <v-card-subtitle>
            Please confirm to release {{ releaseNumber }}
          </v-card-subtitle>
          <!-- <v-card-text>
            <v-radio-group v-model="releaseRadio" mandatory>
              <v-radio label="Release Now" value="radio-1"></v-radio>
              <v-radio
                  label="Release at the expiration date"
                  value="radio-2"
                ></v-radio>
            </v-radio-group>
          </v-card-text> -->

          <v-card-actions class="pb-5 pt-0 justify-space-between">
            <v-btn depressed class="text-capitalize" @click="closeRelease">
              No
            </v-btn>
            <v-btn depressed color="primary" class="text-capitalize" @click="submitRelease">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="extendDialog" max-width="450px">
        <v-card>
          <v-card-title class="font-weight-bold">
            {{ subscribeDialog ? 'Subscribe' : 'Extend Number' }}
          </v-card-title>
          <v-card-subtitle>
            Select a {{ subscribeDialog ? 'subscription' : 'extend' }} period
          </v-card-subtitle>
          <v-card-text>
            <v-radio-group v-model="extendRadio" mandatory>
              <v-radio
                label="Month"
                value="month"
              />
              <v-radio
                label="Year"
                value="year"
              />
            </v-radio-group>
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-space-between">
            <v-btn depressed class="text-capitalize" @click="closeExtend">
              Cancel
            </v-btn>
            <v-btn
              :loading="extendLoading"
              depressed
              color="primary"
              class="text-capitalize"
              @click="subscribeDialog ? upgradeNumber() : submitExtend()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="modal.active" max-width="450px">
        <v-card>
          <v-card-title class="font-weight-bold">
            {{
              modal.type === 'block'
                ? modal.blacklist
                  ? 'Unblock Number'
                  : 'Block Number'
                : 'Unassign callflow'
            }}
          </v-card-title>
          <v-card-subtitle>
            {{
              modal.type === 'block'
                ? modal.blacklist
                  ? `Do you want to unblock ${modal.blacklist.didNumber} number`
                  : 'Enter reason'
                : `Do you want to unassign "${modal.callFlowName}" callflow?`
            }}
          </v-card-subtitle>
          <v-card-text v-if="modal.type === 'block' && !modal.blacklist">
            <v-text-field
              v-model="modal.reason"
              type="text"
              solo
              outlined
              dense
              hide-details
            />
          </v-card-text>
          <v-card-subtitle v-if="modal.type === 'block' && !modal.blacklist">
            Other details
          </v-card-subtitle>
          <v-card-text v-if="modal.type === 'block' && !modal.blacklist">
            <v-text-field
              v-model="modal.otherDetail"
              type="text"
              solo
              outlined
              dense
              hide-details
            />
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-space-between">
            <v-btn depressed class="text-capitalize" @click="closeModal">
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="text-capitalize"
              @click="modal.type === 'block' ? toggleBlockNumber() : unassignCallflow()"
            >
              {{
                modal.type === 'block'
                ? modal.blacklist
                  ? 'Unblock'
                  : 'Block'
                : 'Unassign'
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <vue-snotify />
    </v-card>
    <assign-callflow
      v-if="assignCallflow"
      :dialog="assignCallflow"
      :number="assignCallflowToNumber"
      @close="closeAssignDialog"
    />
  </div>
</template>

<script>
// import SelectComponent from '@/components/SelectComponent'
import AssignCallflow from '@/components/AssignCallflow'
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PhoneNumbersOld',
  components: {
    AssignCallflow
    // SelectComponent
  },
  data: () => {
    return {
      selectAllExtensions: false,
      releaseRadio: null,
      releaseDialog: false,
      extendNumber: null,
      extendRadio: null,
      extendDialog: false,
      extendLoading: false,
      itemsPerPage: 10,
      page: 1,
      pageTotal: 0,
      selectedItems: [],
      releaseNumber: null,
      items: [],
      loading: false,
      queries: {
        order_dir: 'DESC',
        order_by: 'createdOn'
      },
      modal: {
        active: false,
        type: '',
        uuid: '',
        callFlowUuid: '',
        callFlowName: '',
        reason: '',
        otherDetail: ''
      },
      assignCallflow: false,
      assignCallflowToNumber: {},
      headers: [
        {
          text: 'Phone Number',
          align: 'start',
          sortable: false,
          value: 'didNumber',
          class: ['text-caption', 'greyBuoyant', 'one-line'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Started On',
          align: 'start',
          sortable: false,
          value: 'createdOn',
          formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
          class: ['text-caption', 'greyBuoyant', 'one-line'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Expired On',
          align: 'start',
          sortable: false,
          value: 'expireOn',
          formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
          class: ['text-caption', 'greyBuoyant', 'one-line'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Auto Renew',
          value: 'autorenew',
          align: 'center',
          sortable: false,
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant d-flex align-center justify-center'
        },
        {
          text: 'Callflow',
          value: 'callflow',
          align: 'center',
          sortable: false,
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          sortable: false,
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        }
      ],
      callflows: [],
      extensionDialog: false,
      dialogExtensions: [],
      subscribeDialog: false
    }
  },
  watch: {
    selectedDidNumber: {
      deep: true,
      handler (val) {
        if (val) this.numbersList()
      }
    },
    page () {
      this.numbersList()
    }
  },
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts',
      'selectedDidNumber'
    ]),
    ...mapGetters([
      'currentUser',
      'currentCompany'
    ]),
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    countPage () {
      return Math.ceil(this.pageTotal / this.itemsPerPage)
    },
    isDesktop () { return this.$vuetify.breakpoint.mdAndUp },
    isFreeAccount () { return this.currentCompany?.is_free }
  },
  async created () {
    await this.callflowList()
    console.log(this.$route.params, 'this.$route.params')
    this.numbersList()
  },
  methods: {
    ...mapActions([
      'didNumbersList',
      'editExtension',
      'deleteExtension',
      'blockNumber',
      'unblockNumber',
      'getAccountData',
      'getCallflows',
      'getMember',
      'upgradeNumberPaid'
    ]),
    async numbersList () {
      try {
        this.loading = true

        const { data } = await this.didNumbersList({
          ...this.queries,
          offset: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        })
        this.items = data.didList.map(item => ({
          ...item,
          expand: false,
          loaded: false,
          members: this.getAssignedMembers(item.extensions),
          extensions: item.extensions.map(ext => ({ ...ext, isSelected: false })),
          callflow: this.getCallflow(item.callFlowUuid)
        }))
        this.pageTotal = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        if (!this.pageTotal) {
          if (this.$route.params.reload) {
            setTimeout(() => {
              this.numbersList()
            }, 1000)
          } else {
            this.buyNewNumber()
          }
        } else {
          this.loading = false
        }
      }
    },
    async callflowList (params = {}) {
      try {
        this.loading = true
        const { data } = await this.getCallflows(params)
        this.callflows = data.payload.items
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    interact (type, number) {
      switch (type) {
        case 'assign':
          this.assignCallflowToNumber = number
          this.assignCallflow = true
          break
        case 'block':
          this.modal.type = 'block'
          this.modal.uuid = number.uuid
          this.modal.active = true
          this.modal.blacklist = number.blacklist
          break
        case 'extend':
          this.openExtendDialog(number)
          break
        case 'release':
          this.openReleaseDialog(number)
          break
        case 'subscribe':
          this.openExtendDialog(number, true)
          break
      }
    },
    closeAssignDialog ({ message, error }) {
      this.assignCallflow = false
      this.numbersList()
      setTimeout(() => {
        this.assignCallflowToNumber = {}
      }, 1000)
      this.$snotify.notifications = []
      if (error) {
        this.$snotify.error(error.message)
        throw new Error(error)
      }
      if (!message) return
      this.$snotify.success(message)
    },
    async toggleBlockNumber () {
      try {
        if (!this.modal.uuid) return

        if (this.modal.blacklist) {
          await this.unblockNumber(this.modal.blacklist.uuid)

          this.$snotify.notifications = []
          return this.$snotify.success('Number was successfully unblocked')
        }

        const payload = {
          didUuid: this.modal.uuid,
          reason: this.modal.reason,
          otherDetail: this.modal.otherDetail
        }

        const { data } = await this.blockNumber(payload)
        if (!data || !data.success) return

        this.$snotify.notifications = []
        this.$snotify.success('Number was blocked')
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      } finally {
        this.numbersList()
        this.closeModal()
      }
    },
    closeModal () {
      this.modal.active = false
      this.modal.type = ''
      this.modal.uuid = ''
      this.modal.reason = ''
      this.modal.otherDetail = ''
    },
    /* async extensionList () {
      this.loading = true
      await this.$store
        .dispatch('getExtensions')
        .then(({ data }) => {
          this.extensions = data.extensions
          console.log(this.extensions)
        }).catch(err => {
          throw new Error(err)
        }).finally(() => {
          this.numbersList()
        })
    }, */
    async submitRelease () {
      this.$snotify.notifications = []
      try {
        const payload = {
          didNumbers: [this.releaseNumber]
        }
        const data = await this.$store.dispatch('releaseNumber', payload)

        if (!data.success) {
          if (!data.didNumbers || !data.didNumbers.length || !data.didNumbers[0].message) {
            this.$snotify.error('Something went wrong while releasing number')
          } else if (data.didNumbers[0].message.includes('locked')) {
            this.$snotify.error('Number is locked by admin')
          } else {
            this.$snotify.error(data.didNumbers[0].message)
          }
          return
        }
        this.$snotify.success('Successful')

        this.getAccountData(this.$store.state.auth.currentUser.role)
        await this.numbersList()
        if (!this.pageTotal) this.buyNewNumber()
      } catch (e) {
        this.$snotify.error(e)
        throw new Error(e)
      } finally {
        this.releaseNumber = null
        this.releaseDialog = false
      }
      this.releaseDialog = false
      console.log('submit release', this.releaseRadio)
    },
    async submitExtend () {
      try {
        this.extendLoading = true
        const payload = {
          did_number: this.extendNumber.didNumber,
          durationUnit: this.extendRadio
        }

        const data = await this.$store.dispatch('extendNumber', payload)

        console.log(data)
        if (data) {
          window.location.replace(data.url)
        }
      } catch (e) {
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.extendLoading = false
        this.extendNumber = null
        this.extendDialog = false
      }
      this.releaseDialog = false
      console.log('submit release', this.releaseRadio)
    },
    async removeExtension (uuid) {
      try {
        const { data } = await this.deleteExtension(uuid)
        this.$snotify.notifications = []
        this.$snotify.success('Extension has been removed')

        this.numbersList()
        this.dialogExtensions = this.dialogExtensions.filter(e => e.uuid !== uuid)
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    async blockExtension (extension) {
      try {
        const enable = !extension.enable
        const { data } = await this.editExtension({ uuid: extension.uuid, payload: { enable } })
        this.$snotify.notifications = []
        this.$snotify.success(`Extension has been ${enable ? 'enabled' : 'disabled'}`)

        this.numbersList()
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    async upgradeNumber () {
      try {
        const { data } = await this.upgradeNumberPaid({
          durationUnit: this.extendRadio
        })
        if (!data || !data.url) return
        window.location.replace(data.url)
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    /* ChangeAction (val) {
      console.log('ChangeAction', val)
    }, */
    async openExtensionDialog (item, idx) {
      try {
        console.log(item, idx, 'item, idx')
        if (!item.extensions.length) return

        if (!item.loaded) {
          const extensions = []
          for (const ext of item.extensions) {
            if (ext.assignedUserName) continue
            const { data } = await this.getMember(ext.assignedUserUuid)
            extensions.push({
              ...ext,
              assignedUserName: data && data.member ? `${data.member.user_first_name} ${data.member.user_last_name}` : ''
            })
          }
          this.items[idx].loaded = true
          this.items[idx].extensions = extensions
          this.dialogExtensions = extensions
        } else this.dialogExtensions = item.extensions

        this.extensionDialog = true
      } catch (err) {
        console.log(err)
      }
    },
    closeExtensionDialog () {
      this.extensionDialog = false
      this.dialogExtensions = []
    },
    check (e, i) {
      console.log(e, i)
    },
    selectAll (val, index) {
      this.items[index].extensions.forEach((e) => {
        e.isSelected = val
      })

      this.selectedItems = val ? this.items[index] : []
    },
    getAssignedMembers (extensions) {
      const members = []
      extensions.forEach((element) => {
        if (!members.includes(element.assignedUserUuid)) {
          members.push(element.assignedUserUuid)
        }
      })
      return members.length
    },
    getCallflow (uuid) {
      const callflow = this.callflows.find(e => e.uuid === uuid)
      return callflow ? callflow.name : ''
    },
    openReleaseDialog (num) {
      console.log(num, 'numm')
      this.releaseNumber = num
      this.releaseDialog = true
    },
    openExtendDialog (num, subscribe = false) {
      this.extendNumber = num
      this.subscribeDialog = subscribe
      this.extendDialog = true
    },
    closeRelease () {
      this.releaseNumber = null
      this.releaseDialog = false
    },
    closeExtend () {
      this.extendNumber = null
      this.extendDialog = false
      this.subscribeDialog = false
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page !== 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    selectItem (item) {
      if (item.isSelected) {
        this.selectedItems.push(item)
        console.log(this.selectedItems)

        return
      }

      this.selectedItems = this.selectedItems.filter(data => data.id !== item.id)

      console.log(this.selectedItems)
    },
    assignUser (payload, number) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
      this.$store.dispatch('components/setSelectedDidNumber', number)
    },
    buyNewNumber () {
      // this.$snotify.notifications = []
      // if (this.isFreeAccount && this.items.length) return this.$snotify.error('Free user can have only 1 free number.')
      this.$router.push({ name: 'BuyNewNumber' })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  margin: 0
}

.v-list-item__content {
  min-width: 150px !important;
}

.expanded {
  background: #F4F6F8;
}
.phoneNumberItem.canNotExpand{
  cursor: default;
}
.v-list-item {
  @include breakpoint(small) {
    min-height: auto;
    padding: 8px;
  }
}

.page-select {
  max-width: 20%;
}

::v-deep th.one-line span {
  display: block;
  width: max-content;
}
</style>
